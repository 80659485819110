/*Title Card*/
.small-title,
.title-card {
  @include display-flex;
  @include flex-direction(column);
  @include user-select(none);

  -ms-flex: 1 0 auto;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  font-family: 'Rock Salt', cursive;
  padding: 20px;
  position: relative;

  & > .name,
  & > .title {
    @include display-flex;
    @include flex(1);
    margin: 0 auto;
    text-align: center;
  }

  & > .name {
    align-items: flex-end;
    align-self: flex-end;
    box-sizing: border-box;
    font-weight: bold;
    margin: 0;

    h1 {
      border-bottom: 2px inset $vivid-yellow;
      margin: 0;
    }
  }
}

.small-title {
  height: 110px;
  padding: 20px;

  & > .name {
    h1 {
      @include font-size(32px);
    }
  }

  & > .title {
    @include font-size(18px);
  }
}

.title-background {
  &::before {
    background-image: radial-gradient(circle farthest-side, rgba($light-orange, 1), $transparent);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &.small-title {
    &::before {
      opacity: 1;
    }
  }

  &.title-card {
    background-image: radial-gradient(circle closest-side, rgba($white, .25), rgba($light-orange, .75));
    z-index: 100;

    &::before {
      opacity: 0;
    }
  }
}

.title-card {
  cursor: pointer;
  height: 100vh;
  padding: 0;

  & > .name {
    h1 {
      @include font-size(56px);
    }
  }

  & > .title {
    @include font-size(24px);
  }
}
