main {
  &.container {
    margin-left: 65px;
    margin-right: 65px;
  }
}

.container {
  &.small-title {
    margin-left: 65px;
    margin-right: 65px;
  }
}

.modal-open {
  //forced to use this -- weird Bootstrap behavior
  padding-right: 0 !important;

  .modal {
    z-index: 9999;

    &.flex-modal {
      @include display-flex;

      & > .modal-dialog {
        @include display-flex;

        align-items: center;
        z-index: 100;

        & > .modal-content {
          @include display-flex;
          @include flex(1);
          @include flex-direction(column);
        }
      }
    }

    &.login {
      overflow-y: hidden;
    }
  }
}

.nav-pills {
  &.nav-justified {
    & > li {
      vertical-align: middle;
    }
  }
}
