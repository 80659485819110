.input-container {
  margin: 5px;

  .form-control {
    border: 1px solid $black;

    &:focus {
      @include box-shadow(none);
      outline: 0;
    }
  }
}
