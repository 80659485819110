/*Layout*/
.content-section,
.name,
.title {
  align-content: center;
  justify-content: center;
  width: 100%;
}

.content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;

  &-section {
    position: relative;
    text-align: center;

    // &:nth-child(odd) {
    //   @include box-shadow($shadow);
    // }
  }
}

.page-footer {
  background-color: $off-white;
  border-top: 2px solid $vivid-yellow;
  padding-top: 3px;
  width: auto;
}
