// .work-experience {
//   @include box-shadow($shadow);
//   background-color: rgba($light-orange, .8);
//   margin-bottom: 25px;
//   padding: 20px;
//
//   .sub-title {
//     margin-left: 15px;
//   }
//
//   .title {
//     @include font-size(22px);
//   }
// }
