* {
  @include transition(.3s);
}

a {
  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
}

button {
  &:focus {
    outline: none;
  }

  @include user-select(none);
}

html {
  background-color: $off-white;
  background-image: url('imgs/purty-wood.png');
  font-size: $base-font-size;

  body {
    background-color: transparent;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: $base-font-size;
    height: 100vh;
    margin: 0;
    overflow-x: hidden;
  }
}

main {
  @include display-flex;
  @include flex-direction(column);
  @include flex(1);
  @include transition(.3s filter);

  height: 100vh;

  &.de-emphasize {
    @include filter(blur(5px));
  }
}

section {
  @include font-size($base-font-size);
}
