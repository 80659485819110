.carousel {
  &-caption,
  &-control {
    @include user-select(none);
    color: $lime-green;

    &:active,
    &:focus,
    &:hover {
      color: $lime-green;
    }
  }

  &-caption {
    @include display-flex;
    @include flex-direction(row);
    @include flex(1);

    align-content: center;
    align-items: center;
    background: hsla(0, 0%, 100%, .5);
    border-top: 1px solid $black;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 0;
    padding-top: 10px;
    position: relative;
    text-shadow: 1px 1px 1px $black;
    width: 100%;

    &::before {
      @include filter(blur(20px));
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      @include font-size(30px);
    }
  }

  &-control {
    width: 10%;

    &.left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, .0001), rgba(0, 0, 0, .25) 100%);
    }

    &.right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, .0001), rgba(0, 0, 0, .25) 100%);
    }
  }

  &-indicators {
    bottom: 35px;

    li {
      border-color: $lime-green;

      &.active {
        background-color: $lime-green;
      }
    }
  }

  &-inner {
    .item {
      min-height: 450px;

      &.active {
        @include display-flex;
        @include flex-direction(column);

        img {
          @include display-flex;
        }
      }
    }
  }
}
