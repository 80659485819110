/*Navigation*/
/*Breakpoint is 1300px*/
.nav-bar {
  & > .navbar-collapse {
    & > .nav-pills {
      & > li {
        & > a {
          &:hover {
            background-color: $off-white;
            color: $lime-green;
          }

          &.active,
          &:focus {
            background-color: $lime-green;
          }

          &.active {
            &:hover {
              color: $white;
            }
          }

          @include transition(.3s);
          background-color: $black;
          border: 1px solid $black;
          box-sizing: border-box;
          color: $white;
          cursor: pointer;
          padding: 10px;
        }
      }
    }

    padding: 0;
  }

  & > .navbar-header {
    & > .navbar-toggle {
      & > .icon-bar {
        background-color: #fff;
      }

      background-color: $lime-green;
    }
  }

  background-color: $black;
  padding: 2px;
  width: 100%;
  z-index: 20;
}
