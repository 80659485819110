* {
  .auto {
    &-center {
      margin-left: auto;
      margin-right: auto;
    }

    &-height {
      height: auto;
    }
  }

  &.hide {
    &-horizontal,
    &-horizontal-vertical,
    &-vertical {
      @include box-sizing(border-box);
      @include transition(.3s);

      border: 0;
      margin: 0;
      min-height: 0;
      overflow: hidden;
      white-space: nowrap;
    }

    &-horizontal,
    &-horizontal-vertical {
      height: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    &-horizontal-vertical,
    &-vertical {
      padding-left: 0;
      padding-right: 0;
      width: 0;
    }
  }
}

.bold {
  font-weight: bold;
}

.bottom-spacer {
  margin-bottom: 20px;
}

.bullet-title {
  display: inline-block;
}

.center {
  text-align: center;

  &::after,
  &::before {
    text-align: center;
  }
}

.clickable {
  cursor: pointer;
}

.fixed-height {
  border-top: 2px solid $vivid-yellow;
  height: 100%;
  overflow: auto;
  padding: 10px;
}

.hidden {
  display: none;
}

.no-bullets {
  list-style-type: none;
}

.right {
  text-align: right;
}

.side-padding {
  padding: 0 5px;
}

.spacer {
  margin: 10px 0;
}

.top-spacer {
  margin-top: 15px;
}
