$border-radius: '250px / 40px';

.profile-picture-container {
  @include border-radius(unquote($border-radius));
  @include box-shadow($shadow);
  margin: 8px;
  max-height: 300px;
  overflow: hidden;
  width: 200px;
}

.photo-stack {
  @include box-shadow(2px 2px 5px rgba(0, 0, 0, .3));
  @include transition(.3s);
  background-color: #fff;
  border: 6px solid #fff;
  box-sizing: border-box;
  display: inline-block;
  height: 150px;
  margin: 20px;
  position: relative;
  width: 150px;
  z-index: 500;

  &:hover {
    &::after {
      @include transform(rotate(6deg));
    }

    &::before {
      @include transform(rotate(-7deg));
    }

    img {
      @include transition(transform .5s);
      @include transform(rotate(-5deg));
    }
  }

  &::after,
  &::before,
  img {
    border: 6px solid #fff;
    cursor: pointer;
    height: 150px;
    width: 150px;
  }

  &::after,
  &::before {
    @include box-shadow(2px 2px 5px rgba(0, 0, 0, .3));
    @include transition(.3s);
    content: '';
    position: absolute;
    z-index: -1;
  }

  &::after {
    @include transform(rotate(4deg));
    background: #768590;
    content: '';
    left: 0;
    top: 5px;
  }

  &::before {
    @include transform(rotate(-5deg));
    background: #eff4de;
    left: -10px;
    top: 0;
  }
}
