/*Variables*/
$base-font-size: 16px;
$link-color: #337ab7;
$shadow: 0 5px 10px -5px rgba(0, 0, 0, .4);

/*Colors*/
$off-white: #faf8f2;
$lime-green: #18a676;
$light-orange: #f7eac8;
$vivid-yellow: #e8b71a;
$dark-vivid-yellow: #d9aa16;
$strong-yellow: #b58e12;
$bright-red: #db3340;
$strong-red: #b3202b;
$bright-blue: #28abe3;
$brighter-blue: #25a0da;
$white: #fff;
$black: #000;
$transparent: rgba(255, 255, 255, 0);

// Browser Prefixes - Which CSS prefixes should be used?
$use-webkit: true !default;
$use-old-webkit: true !default;
$use-moz: true !default;
$use-ms: true !default;
$use-old-o: true !default;
$use-o: true !default;

//Prefixes
$webkit: -webkit-;
$moz: -moz-;
$ms: -ms-;
$o: -o-;

/*Imports*/
@import 'functions';
@import 'mixins';

@import 'about-me';
@import 'animations';
@import 'bootstrap-override';
@import 'buttons';
@import 'carousel-modifier';
@import 'contact-me';
@import 'education';
@import 'element-base-styles';
@import 'flex-classes';
@import 'froala-modifier';
@import 'images';
@import 'inputs';
@import 'layout';
@import 'loading';
@import 'modal-modifier';
@import 'nav-bar';
@import 'projects';
@import 'skills';
@import 'substates';
@import 'title-card';
@import 'utility';
@import 'work-experience';

section {
  &.container {
    @include border-radius(4px);
    @include box-shadow($shadow);
    @include display-flex;
    @include flex(1);
    @include flex-direction(column);

    background-color: $off-white;
    border: 15px solid $off-white;
    margin-bottom: 50px;
    padding: 15px;
    position: relative;

    & > .fixed-height {
      background-clip: padding-box;
      background-color: $off-white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
